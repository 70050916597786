@import-normalize;
@tailwind base;
@tailwind components;
@tailwind utilities;
@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@400;500;700&display=swap');

*,
*::before,
*::after {
  box-sizing: border-box;
  font-family: "Poppins", sans-serif;
  margin: 0;
  padding: 0;
}

/* Chatbot container styles */
.chatbot-container {

  display: flex;
  align-items: center;
}

.smooth-transition {
  transition: all 1s ease;
  /* You can adjust the duration (0.3s) and easing function (ease) as needed */
}


/* Define the bounce animation using keyframes */
@keyframes bounce {

  0%,
  20%,
  50%,
  80%,
  100% {
    transform: translateY(0);
  }

  40% {
    transform: translateY(-10px);
  }

  60% {
    transform: translateY(-5px);
  }
}

/* Apply the "bounce" animation when the element has the "bounce" class */
.bounce {
  animation-name: bounce;
  animation-duration: 1s;
  animation-iteration-count: infinite;
}




@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateX(100%);
    /* Initial position */
  }

  to {
    opacity: 1;
    transform: translateX(0);
    /* Final position */
  }
}

.visible {
  animation: fadeIn 0.5s ease;
  /* Animation duration and timing function */
}





.prev-btn,
.next-btn {
  background-color: rgba(255, 255, 255, 0.8);
  border: none;
  cursor: pointer;
  z-index: 2;
}

.indicators .indicator {
  transition: background-color 0.3s ease-in-out;
}

/* styles.css (or your preferred CSS file) */
.truncat {
  max-width: 25vw;
  /* Set your desired max-width */
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}


.test-p {
  width: 100%;
  /* Set the width of the div to 100% of its parent */
  overflow: hidden;
  /* Hide the overflowing content */
  white-space: nowrap;
  /* Prevent text wrapping */
  text-overflow: ellipsis;
}








.price-slider {
  position: relative;
}

.price-label {
  position: absolute;
  top: -25px;
  /* Adjust as needed */
  transform: translateX(-50%);
  /* Additional styling for the label */
}

.slider {
  width: 100%;
  /* Additional styling for the slider */
}








@layer components {

  /* SideBar */
  .sidebar {
    @apply flex flex-col h-full bg-dark-grey p-2 pt-4 relative duration-300 border-r-[2px] border-slate-600;
  }

  .sidebar__app-bar {
    @apply flex justify-center gap-4 items-center;
  }

  .sidebar__app-title {
    @apply text-white font-medium text-xl duration-300;
  }

  .sidebar__btn-close {
    @apply cursor-pointer duration-300 text-white justify-end;
  }

  .sidebar__btn-icon {
    @apply w-8 h-8;
  }

  .sidebar__app-logo {
    @apply cursor-pointer duration-300 text-white;
  }

  /* SideBar Nav */
  .nav {
    @apply flex justify-start w-full;
  }

  .nav__item {
    @apply px-4 py-3 flex items-center gap-x-4 w-full rounded-md cursor-pointer hover:bg-light-white text-white text-base;
  }

  #nav__context {
    @apply overflow-x-hidden h-5/6 space-y-4 overflow-y-auto
  }

  .nav__context {
    @apply flex flex-col border-neutral-600
  }

  .nav__context h1 {
    @apply text-lg font-bold
  }


  .nav__context h2 {
    @apply font-bold mb-2
  }

  .nav__context p {
    @apply text-sm
  }

  .nav__context input {
    @apply rounded-lg p-2
  }

  .nav__icons {
    @apply cursor-pointer duration-300 text-white text-xl;
  }

  .nav__bottom {
    @apply flex justify-end items-stretch;
  }

  .nav__msg {
    @apply flex bg-yellow-900 rounded-xl p-4 text-white;
  }

  .nav__p {
    @apply font-mono;
  }

  #context_elements {
    @apply text-xs;
  }

  /* RightPanel */
  .right-panel-liens-utiles {
    @apply p-2;
  }

  .list-links {
    @apply flex flex-col w-full;
  }

  .link {
    @apply flex items-center justify-start text-white text-sm w-full no-underline;
    animation: fadeIn 0.5s ease;
    /* Animation duration and timing function */
  }

  .link:hover {
    @apply underline;
  }

  .right-panel-list-experts {
    @apply flex flex-col w-full;
  }

  .right-panel-expert {
    @apply flex flex-col;
  }

  .right-panel-expert-top {
    @apply w-[100%] flex items-start justify-start;
  }

  .expertises {
    @apply truncate;
  }

  .expertises:hover {
    @apply flex-wrap;
    white-space: normal;
    overflow: visible;
  }






  .bg-first-oliver {
    @apply bg-first-oliver-color
  }

  .bg-second-oliver {
    @apply bg-second-oliver-color
  }

  .text-first-oliver {
    @apply text-first-oliver-color
  }

  .text-second-oliver {
    @apply text-second-oliver-color
  }

  .bg-first-infogreffe {
    @apply bg-first-infogreffe-color
  }

  .bg-second-infogreffe {
    @apply bg-second-infogreffe-color
  }

  .text-first-infogreffe {
    @apply text-first-infogreffe-color
  }

  .text-second-infogreffe {
    @apply text-second-infogreffe-color
  }










  #icon {
    @apply w-[10%] h-[34px] mr-2;
  }

  /* CHATVIEW */
  .chatview {
    @apply flex flex-col h-full duration-300 overflow-hidden relative bg-slate-200 w-max dark:bg-light-grey;
  }

  .chatview__chatarea {
    @apply flex-grow justify-between w-full flex flex-col shadow-md overflow-y-auto;

  }

  .chatview__chatarea::-webkit-scrollbar {
    width: 0;
  }

  .form {
    @apply flex justify-between flex-col items-stretch sm:flex-row m-2 bottom-0 left-0 right-0 text-black dark:text-white duration-300;
  }

  .dropdown {
    @apply dark:bg-light-grey h-16 px-3 rounded-xl bg-light-white hover:bg-slate-200 hover:dark:bg-dark-grey border-2 border-slate-400 dark:border-slate-500 focus:ring-offset-indigo-200 text-black dark:text-white transition ease-in-out duration-300 text-center text-base font-semibold shadow-md focus:outline-none focus:ring-2 focus:ring-offset-2;
  }

  .message__img {
    @apply rounded-lg shadow-lg bg-cover bg-gray-400 w-[512px] duration-300 ease-out;
  }

  .chatview__textarea-message {
    @apply bg-white ml-0 sm:ml-2 dark:bg-light-grey text-xl h-16 sm:w-full grow max-h-[20rem] min-h-[4rem] px-2 border-2 border-slate-400 dark:border-slate-500 overflow-y-hidden mx-2 focus:ring-offset-indigo-200 focus:ring-2 focus:ring-offset-2 resize-y rounded-xl;
  }

  .chatview__btn-send {
    @apply h-16 w-16 px-4 rounded-full hover:bg-slate-200 hover:dark:bg-dark-grey border-2 border-slate-400 dark:border-slate-500 focus:ring-offset-indigo-200 text-black dark:text-white transition ease-in-out duration-300 text-center text-base font-semibold shadow-md focus:outline-none focus:ring-2 focus:ring-offset-2;
  }

  .chatview__btn-send:disabled {
    @apply opacity-40 transition ease-in duration-300 text-center text-base font-semibold shadow-md;
  }

  /* ChatMessage */
  .message {
    @apply py-10 md:px-14 gap-2 flex items-start justify-end;
  }

  .message__wrapper {
    @apply flex-grow flex-wrap w-max;
  }

  .message__markdown {
    @apply text-lg text-slate-700 dark:text-slate-200;
  }

  .message__createdAt {
    @apply text-sm font-thin italic text-slate-600 dark:text-slate-300;
  }

  .system_message__pic {
    @apply h-8 w-8 mx-2 flex items-center justify-center text-xl;
    transition: transform 10s ease-in-out;
  }

  .user_message__pic {
    @apply h-8 w-8 bg-slate-200 mx-2 rounded-full flex items-center justify-center text-xl;
    transition: transform 10s ease-in-out;
  }



  .message__thinking {
    @apply animate-pulse duration-1000 h-12 dark:text-white text-black;
  }

  /* filters buttons */

  .filter_button_suivre_formalite {
    @apply font-medium border border-2 border-blue-logo-color text-blue-logo-color rounded px-2 py-1 hover:bg-blue-logo-color hover:text-white
  }

  .filter_button_checkup_entreprise {
    @apply font-medium border border-2 border-orange-logo-color text-orange-logo-color rounded px-2 py-1 hover:bg-orange-logo-color hover:text-white
  }

  .filter_button_decision_justice {
    @apply font-medium border border-2 border-[#6FAD9E] text-[#6FAD9E] rounded px-2 py-1 hover:bg-[#6FAD9E] hover:text-white
  }

  .filter_button_demarche_administrative {
    @apply font-medium border border-2 border-[#955972] text-[#955972] rounded px-2 py-1 hover:bg-[#955972] hover:text-white
  }

  .filter_button_reglementation_francaise {
    @apply font-medium border border-2 border-[#CAB98B] text-[#CAB98B] rounded px-2 py-1 hover:bg-[#CAB98B] hover:text-white
  }

  .filter_button_service_infogreffe {
    @apply font-medium border border-2 border-[#6B85B6] text-[#6B85B6] rounded px-2 py-1 hover:bg-[#6B85B6] hover:text-white
  }

  /* Signin Page */


  .loginPage {
    @apply flex w-full h-full bg-white;
  }

  .loginInfosContainer {
    @apply hidden md:flex flex-col w-[70%] bg-white justify-between;
  }

  .loginWords {
    @apply h-[54.5%] flex flex-col justify-center items-center space-y-10 text-4xl text-white font-extralight relative;
  }

  .loginWordsLogo {
    @apply absolute left-8 top-8 w-20;
  }

  .loginWordsW1 {
    @apply w-full text-center mr-[40%];
  }

  .loginWordsW3 {
    @apply w-full text-center ml-[40%];
  }

  .loginInfos {
    @apply bg-zinc-500 flex flex-col justify-center px-20 h-[45%] text-white text-sm lg:text-base font-light space-y-4 justify-center;
  }


  .loginContainer {
    @apply justify-between h-full w-full md:w-[40%] flex flex-col justify-center items-center;
  }


  .loginContainerLogo {
    @apply w-60 pt-8;
  }

  .loginContainerConnexion {
    @apply flex flex-col items-center w-full mt-10;
  }

  .loginContainerConnexion>p {
    @apply text-center text-slate-500 text-xl font-semibold mb-8;
  }

  .loginContainerFooter {
    @apply flex text-xs w-full md:w-[99.5%] h-14 justify-around p-4 items-center text-white;
  }

  .loginButtonWrapper {
    @apply flex justify-around space-x-2 text-white font-semibold w-full;
  }

  .loginButtonWrapper button {
    @apply bg-blue-logo-color p-2 rounded-lg py-2 px-8;
  }

  .signupFormContainer {
    @apply flex flex-col items-center w-full;
  }

  .signupFormContainer .text-input {
    @apply bg-light-white my-2 border-slate-400 border-2 rounded-lg w-full
  }

  .signupFormContainerForm {
    @apply flex flex-col items-center;
  }

  .signupFormContainerForm button {
    @apply bg-blue-logo-color p-2 rounded-lg py-2 px-8 text-white my-4;
  }

  .signupFormContainerContinuer {
    @apply bg-blue-logo-color p-2 rounded-lg py-2 px-8;
  }

  .signupFormContainerRetour {
    @apply text-sm font-semibold;
  }

  .forgotPasswordButton {
    @apply text-sm font-semibold;
  }


  .loginContainerContent .text-input::placeholder {
    @apply p-2;
  }


  .loginContainerContent .text-input[value] {
    @apply text-black p-2;
  }


  .signupWithGoggle {
    @apply flex flex-row items-center space-x-2 text-slate-500 font-semibold my-4 cursor-pointer;
  }

  #signupPassword {
    @apply flex items-center relative flex flex-col w-full;
  }

  .error_message {
    @apply text-red-600;
  }

  .cgus h5 {
    @apply text-2xl font-semibold my-4;
  }

  .cgus img {
    @apply w-[30%]
  }




  .article {
    @apply text-blue-500 cursor-pointer hover:underline;
  }

  .modal {
    @apply w-full;
  }

  /* adminpanel */
  .adminPanelContainer {
    @apply flex flex-col space-y-4
  }

  .adminPanelOnglets>button {
    @apply p-1 rounded-md cursor-pointer text-base;
  }

  .adminPanelOnglets {
    @apply flex space-x-4;
  }

  .ficheRenforcementForm {
    @apply flex flex-col space-y-4 items-center;
  }

  .ficheRenforcementForm>div {
    @apply w-full;
  }

  .italic-placeholder::placeholder {
    font-style: italic;
    font-size: 0.9rem;
  }




}

::-webkit-scrollbar {
  @apply bg-transparent w-1;
}

::-webkit-scrollbar-thumb {
  @apply bg-light-grey dark:bg-dark-grey rounded-xl;
}

.w-max {
  width: 100%;
}

.ca-progress-bar {
  display: flex;
  justify-content: center;
  margin-top: -35px;
}

.ca-progress-input {
  padding: 0 !important;
  margin: 0 !important;
  border-radius: 2px !important;
  height: 5px !important;
  padding: 0 !important;
  border: none !important;
  background: #dbdbdb;
  width: 350px;
  -webkit-appearance: none;
  appearance: none;
}

.ca-progress-input::-webkit-slider-thumb {
  -webkit-appearance: none !important;
  appearance: none !important;
  width: 0 !important;
  height: 0 !important;
  background: transparent;
  cursor: pointer !important;
}

.ca-progress-input::-moz-range-thumb {
  width: 0 !important;
  height: 0 !important;
  background: transparent;
  cursor: pointer !important;
}

.ca-progress-input {
  -webkit-appearance: none !important;
  appearance: none !important;
  border: none !important;
  background: #dbdbdb;
  /* Assure-toi que cette couleur est celle que tu souhaites */
  width: 350px !important;
}

.ca-progress-input:focus {
  outline: none !important;
}


.ca-progress-bubble {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  text-align: center;
  width: 170px;
  margin: -25px 0px;
  color: #aaa;
  font-size: 12px;
}

.btn-ca {
  padding: 20px 15px;
  border-radius: 15px;
  cursor: pointer;
  margin: 10px;
  width: 210px;
  text-align: center;
}

.buttons {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 10px;
}

.btn-row {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.slider-div {
  display: none;
  width: 95%;
  justify-content: center;
  align-items: center;
  margin: 50px;
}

.slider-div span {
  color: #888;
}

.slider-min {
  margin-right: 10px;
}

.slider-wrap {
  position: relative;
  width: 78%;
}

.bubble {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  text-align: center;
  width: 180px;
  margin: -50px 0px;
}

.slider-input {
  margin-bottom: 0 !important;
  width: 100% !important;
  background-color: var(--grey4);
  border-radius: 2px !important;
  height: 5px !important;
  background: #dbdbdb;
  -webkit-appearance: none;
  padding: 0 !important;
  border: none !important;
}


.slider-max {
  margin-left: 10px;
}

.col-lg-4 {
  flex: 0 0 auto;
  width: 30%;
}

.promo__box {
  background: #f2f2f2;
  color: #4F4D60;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16);
  border-radius: 10px;
  padding: 25px;
  font-weight: 500;
  font-size: 20px;
  position: relative;
}

.color__azure {
  color: #0F6ACC !important;
}

.public .promo__body {
  color: #4F4D60;
  font-weight: 500;
  font-size: 23px;
  min-height: 100px;
  margin-top: 20px;
}

.public .promo__image {
  min-height: 120px;
}

.promo__image {
  min-height: 120px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 120px;
}

.color__green {
  color: #5FFE96 !important;
}

.sidebar {
  width: 400px;
  top: 0;
  bottom: 0;
  background-color: #006acc;
  padding: 2rem;
  position: fixed;
  transition-duration: .5s;
  overflow-y: auto;
}

.menu__step {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 10px;
}

.menu__step--text {
  font-size: 17px;
  font-weight: 500;
  color: #fff;
  line-height: 21px;
  width: 150px;
}

.menu__step--text__thimatic {
  font-size: 17px;
  font-weight: 500;
  color: #1e4690;
  line-height: 21px;
}


.menu__step__dashed--number {
  width: 72px;
  height: 72px;
  border-radius: 50%;
  border: 2px dashed #fff;
  margin-right: 15px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  font-size: 22px;
  line-height: 21;
  font-weight: 500;
}

.menu__step__dashed--number-sol {
  position: relative;
  width: 72px;
  height: 72px;
  margin-right: 15px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  font-size: 22px;
  line-height: 21px;
  font-weight: 500;
}

.menu__step__dashed--number-sol .dashed-circle {
  position: absolute;
  top: 0;
  left: 0;
}

.menu__step__dashed--number-sol span {
  position: relative;
  z-index: 1;
}


.menu__step--number {
  width: 72px;
  height: 72px;
  border-radius: 50%;
  border: 2px solid #fff;
  margin-right: 15px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  font-size: 22px;
  line-height: 21;
  font-weight: 500;
}

.menu__step--number__thimatic {
  width: 72px;
  height: 72px;
  border-radius: 50%;
  border: 2px solid #1e4690;
  margin-right: 15px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #1e4690;
  font-size: 22px;
  line-height: 21;
  font-weight: 500;
}

/* .menu__step--footer {
  display: flex;
  justify-content: space-between;
} */

.menu__step--footer .menu__step__problem {
  display: flex;
  align-items: center;
}

.menu__step--footer .menu__step__problem--img {
  max-width: 27px;
  margin-right: 10px;
}

.menu__step--footer .menu__step__problem--from {
  font-size: 11px;
  font-weight: 400;
  color: #fff;
  margin-bottom: 0;
}

.menu__step--footer .menu__step__problem--price {
  font-size: 21px;
  color: #fff;
  margin-bottom: 0;
}

.navigation {
  padding-left: 0;
}

.navigation__item {
  width: 45px;
  height: 45px;
  list-style: none;
  display: inline-block;
  border-radius: 50%;
  background-color: #fff;
  overflow: hidden;
  position: relative;
}

.navigation__item.arrow_back {
  margin-right: 10px !important;
}


#godashboard {
  width: auto;
  border-radius: 32px;
}

.view {
  display: none;
}

.d-block {
  display: block !important;
}

.view .card-category {
  margin-left: 0;
  padding: 0;
  display: flex;
}

.card-category {
  padding: 1rem 1.5rem;
  position: relative;
  margin-left: 10px;
  margin-bottom: 2.5rem;
  box-shadow: 2px 3px 7px #dcdcdc;
  border-radius: 8px;
  background-color: #fff;
}

.view .card-category .border-left {
  position: relative;
  height: auto;
  flex: 0 0 63px;
  z-index: 1;
  left: 0;
}

/* .card-category-fis .border-left {
  background-color: #fed23e;
} */

.border-left {
  border-left: none !important;
  border-radius: 8px 0 0 8px;
  position: absolute;
  width: 15px;
  left: -8px;
  top: 0;
  bottom: 0;
  z-index: 0;
  /* background-color: #161C67; */
}

.view .card-category--content {
  padding: 1rem 1.5rem;
  flex: 1;
}

.view .card-category .border-left--img {
  margin: 1rem auto 0 auto;
  display: block;
}

.border-left img {
  max-width: 50px;
}

.view .card-category__header {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.view .card-category__name {
  font-size: 18px;
  font-weight: 500;
  line-height: 27px;
  text-transform: uppercase;
  color: #1e4690;
  word-break: break-word;
}

.view .card-category__number-obligation {
  width: 60px;
  min-width: 60px;
  height: 60px;
  border: 2px solid #1e4690;
  border-radius: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.view .card-category__number--value {
  font-size: 22px;
  font-weight: 500;
  line-height: 17px;
  color: #1e4690;
  margin-bottom: 0;
}

.view .card-category__number-droit {
  width: 60px;
  min-width: 60px;
  height: 60px;
  border: 2px dashed #1e4690;
  border-radius: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.view .card-category__number-solution {
  position: relative;
  width: 60px;
  min-width: 60px;
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.view .card-category__number-solution .dashed-circle {
  position: absolute;
  top: 0;
  left: 0;
}

.view .card-category__number-solution .card-category__number--value {
  position: relative;
  z-index: 1;
  color: #1e4690;
  font-size: 22px;
  line-height: 21px;
  font-weight: 500;
}


.view .card-category__detail {
  font-size: 12px;
  font-weight: 300;
  line-height: 16px;
  color: #707070;
  margin-bottom: 10px;
  position: relative;
  margin-top: 20px;
}

.view .card-category__detail::after {
  content: "";
  background: #707070;
  height: 1px;
  width: calc(100% - 120px);
  display: block;
  position: absolute;
  right: 0;
  top: 10px;
}

.view .card-category__items {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  margin-top: 30px;
}

.view .card-category__item {
  @apply bg-[#F7F7F7] p-[5px_12px] rounded-[5px] h-9 flex items-center justify-between basis-[calc(50%_-_15px)];
}

.view .card-category__item--text {
  font-size: 9.5px;
  font-weight: 500;
  line-height: 11px;
  text-transform: uppercase;
  letter-spacing: -5;
  margin-bottom: 0;
}

.view .card-category__item__number {
  width: 26px;
  height: 26px;
  border-radius: 26px;
  background: #1e4690;
  color: #fff;
  flex-shrink: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}

.view .card-category__item__number--value {
  font-size: 11px;
  font-weight: 400;
  line-height: 1;
}

@media (min-width: 768px) {
  .col-md-6 {
    flex: 0 0 auto;
    width: 50%;
  }
}

/* @media (min-width: 576px) {
  .col-sm-12 {
    flex: 0 0 auto;
    width: 100%;
  }
} */

.position-relative {
  position: relative !important;
}

.title {
  color: #5FFE96;
  margin-top: 0;
  position: relative;
}

.f-60 {
  font-size: 60px !important;
}

.text-light {
  color: #f8f9fa !important;
}

.blog__hero {
  max-height: 450px !important;
  min-height: 325px !important;
}

.section__area {
  padding: 50px 0;
  position: relative;
}


.bg__azure {
  background-color: #0F6ACC !important;
}

.overflow-visible {
  overflow: visible !important;
}

.hero h3 {
  font-size: 26px;
  position: relative;
}

.search__box {
  margin: 30px 0 80px;
  position: relative;
  z-index: 3;
}

.search__wrapper {
  display: flex;
  background: #fff;
  max-width: 580px;
  border-radius: 30px;
  padding: 7px 0;
}

.search__wrapper input:first-child {
  flex: 1;
}

.search__wrapper input {
  border: 0;
  font-weight: 500;
  border-radius: 30px;
  padding: 10px 20px;
  color: #707070;
  font-style: italic;
}

.search__wrapper button {
  background: transparent;
  border: 0;
  color: #00369A;
  width: 50px;
}

.category__list {
  background: #E5F0FF;
  border-radius: 50px;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16);
  padding: 30px;
  position: relative;
  z-index: 3;
}

.hero__bg::before {
  content: "";
  background: url(assets/hero-bottom.svg) no-repeat scroll 0 0 / 100%;
  position: absolute;
  height: 562px;
  width: 631px;
  right: 0px;
  bottom: -245px;
  transition: right 0.5s, bottom 0.5s;
}

.scrolling .hero__bg.bg__azure.one::before {
  right: 35px;
}

.carousel-control-prev-icon,
.carousel-control-next-icon {
  background-color: #6b21a8;
  /* Couleur purple-500 de Tailwind */
  border-radius: 50%;
  width: 30px;
  height: 30px;
}

/* Customisation des indicateurs */
.carousel-indicators li {
  background-color: #6b21a8;
  /* Couleur purple-500 de Tailwind */
}

.carousel-indicators li.active {
  background-color: #a855f7;
  /* Couleur purple-400 de Tailwind pour un peu de contraste */
}

.partenaire__image {
  background: #ebebeb;
  height: 210px;
  display: flex;
  align-items: center;
  justify-content: center;
}

@keyframes fadeIn {
  from {
      opacity: 0;
  }
  to {
      opacity: 1;
  }
}

.animate-fade-in {
  animation: fadeIn 1.5s ease-in;
}